import { TeamSeason } from '@index/interfaces/team-season';
import { Timestamp } from 'firebase/firestore';

import { GthModel } from './model';

export class GthTeamSeasonModel extends GthModel<TeamSeason> {
  get id() {
    return this.model.id;
  }

  get label() {
    return this.model.label;
  }

  get description() {
    return this.model.description;
  }

  get imageURL() {
    return this.model.imageURL;
  }

  get startDate() {
    return this.handleFirestoreDate(this.model.startDate);
  }

  get endDate() {
    return this.handleFirestoreDate(this.model.endDate);
  }

  get status() {
    return this.model.status;
  }

  get archived() {
    return this.model.archived;
  }

  get archivedDate() {
    return this.handleFirestoreDate(this.model.archivedDate);
  }

  get formId() {
    return this.model.formId;
  }

  get scheduleURLs() {
    return this.model.scheduleURLs;
  }

  constructor(id: string, model: TeamSeason) {
    super(id, model);
  }

  handleFirestoreDate(date?: Date | Timestamp) {
    if (!date) return;
    if (date instanceof Date) {
      return date;
    } else {
      return date.toDate();
    }
  }
}
