import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TeamSeason, TeamSeasonGroup } from '@index/interfaces/team-season';
import { DBUtil } from '@index/utils/db-utils';
import { GthTeamSeasonModel } from '@sentinels/models/team-season';
import { GthTeamSeasonGroupModel } from '@sentinels/models/team-season-group';
import { map } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';

@Injectable({
  providedIn: 'root',
})
export class TeamSeasonService {
  afs = inject(AngularFirestore);

  baseRef(teamId: string) {
    return this.afs.collection(DBUtil.Team).doc(teamId).collection(DBUtil.TeamSeasons);
  }

  groupBaseRef(teamId: string) {
    return this.afs.collection(DBUtil.Team).doc(teamId).collection(DBUtil.TeamSeasonGroups);
  }

  create(teamId: string, value: Partial<TeamSeason>) {
    const id = this.afs.createId();
    return from(
      this.baseRef(teamId)
        .doc(id)
        .set(Object.assign({}, { id }, value)),
    );
  }

  createGroup(teamId: string, value: Partial<TeamSeasonGroup>) {
    const id = this.afs.createId();
    return from(
      this.groupBaseRef(teamId)
        .doc(id)
        .set(Object.assign({}, { id }, value)),
    );
  }

  update(teamId: string, id: string, dataToUpdate: { [key: string]: any }) {
    return from(this.baseRef(teamId).doc(id).update(dataToUpdate));
  }

  updateGroup(teamId: string, id: string, dataToUpdate: { [key: string]: any }) {
    return from(this.groupBaseRef(teamId).doc(id).update(dataToUpdate));
  }

  delete(teamId: string, id: string) {
    return from(this.baseRef(teamId).doc(id).delete());
  }

  deleteGroup(teamId: string, id: string) {
    return from(this.groupBaseRef(teamId).doc(id).delete());
  }

  getAll(teamId: string) {
    return this.baseRef(teamId)
      .get()
      .pipe(
        map((docs) => {
          if (docs.empty) return [];
          return docs.docs.map(
            (doc) => new GthTeamSeasonModel(doc.id, doc.data() as TeamSeason),
          );
        }),
      );
  }

  getAllGroups(teamId: string) {
    return this.groupBaseRef(teamId)
      .get()
      .pipe(
        map((docs) => {
          if (docs.empty) return [];
          return docs.docs.map(
            (doc) => new GthTeamSeasonGroupModel(doc.id, doc.data() as TeamSeasonGroup),
          );
        }),
      );
  }
}
