import { TeamSeasonGroup } from '@index/interfaces/team-season';
import { Timestamp } from 'firebase/firestore';

import { GthModel } from './model';

export class GthTeamSeasonGroupModel extends GthModel<TeamSeasonGroup> {
  get id() {
    return this.model.id;
  }

  get label() {
    return this.model.label;
  }

  get description() {
    return this.model.description;
  }

  get imageURL() {
    return this.model.imageURL;
  }

  get archived() {
    return this.model.archived;
  }

  get archivedDate() {
    return this.handleFirestoreDate(this.model.archivedDate);
  }

  get seasonIds() {
    return this.model.seasonIds;
  }

  get status() {
    return this.model.status;
  }

  constructor(id: string, model: TeamSeasonGroup) {
    super(id, model);
  }

  handleFirestoreDate(date?: Date | Timestamp) {
    if (!date) return;
    if (date instanceof Date) {
      return date;
    } else {
      return date.toDate();
    }
  }
}
